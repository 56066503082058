export type AppRoutes = {
    register: {
        root: string
        demo: string
        live: string
        ib: string
    }
    welcome: {
        demo: string
    },
    account: {
        login: string
        check2fa: string
        createAccount: {
            demo: string
            live: string
        }
        simplified: {
            accountCreated: string
            address: string
            pendingApproval: string
        }
        ib: {
            q1: string
            q2: string
            legal: string
            accountCreated: string
            address: string
            pendingApproval: string
            verify: {
                id: string
                address: string
            }
        }
        validateEmail: string
        checkEmail: string
        congrats: string
        chessSponsorship: string
        clientJourney: string
        accountType: string
        traderClassification: string
        individualFailed: string
        individualRefer: string
        consent: string
        phoneNumber: string
        smsVerification: string
        resetPasswordStep1: string
        resetPasswordStep2: string
        resetPasswordStep3: string
        resetPasswordStep4: string
        deposit: string
        verify: {
            root: string
            id: string
            address: string
        },
        personalInformation: string
        contactAndAddress: string
        financialDetails: string
        financialDetailsSecondPage: string
        experience: string
        appropriatenessTest: string
        appropriatenessTestAdditional: string
        selectPlatform: string
        pammQuestionnaire: string
        corporate: {
            corporateInformation: string
            contacts: string
            address: string
            placeOfBusiness: string
            regulation: string
            representative: string
            directors: string
            shareholders: string
            selectPlatform: string
            financialDetails: string
            experience: string
            appropriatenessTest: string
            appropriatenessTestAdditional: string
            chessSponsorship: string
            forexConfirmation: string
            verify: {
                share: string
                address: string
            },

        }
        usaCitizenship: string
        foreignExchangeBusiness: string
        pepLegalConsent: string
        forexConfirmation: string
    }
    dashboard: {
        accounts: {
            root: string
            demo: {
                changePassword: string
            }
            live: {
                changePassword: string
            }
            createDemo: string
            createLive: string
            mtPassword: string
            new: string
        },
        notifications: string
        funds: {
            deposit: string
            status: string
            transfer: string
            history: string
            withdraw: string
            cancelWithdraw: string
            bankAccounts: string
            addBankAccount: string
            editBankAccount: string
            addBankAccountSuccess: string
            confirmWithdraw: string
            phoneValidation: string
            emailValidation: string
            transferFailed: string
            tradeSummary: string
        }
        downloads: string
        documents: string
        partnerships: string
        subscription: string
        tools: {
            vps: string
            trading: string
            myFxBook: string
            market_buzz: string
            featured_ideas: string
            economic_calendar: string
            analyst_view: string
            traders_gym: string
            signal_centre: {
                start: string
                indicators: string
                dashboard: string
            }
        },
        support: {
            faq: string
            contact: string
            documents: string
            clientRequest: string
            clientRequestNew: string
        },
        referAFriend: string
        thinkCopy: string
        thinkCapital: string
        verify: {
            root: string
            id: string
            address: string
            companyRegister: string
            companyAddress: string
            companyShare: string
            success: string
        },
        approved: string
        marketing: string
    },
    profile: {
        root: string
        personalDetails: string
        uploadDocuments: string
        systemSettings: {
            root: string,
            loginDetails: string
            security: string
            language: string
        },
    },
    ib: {
        dashboard: string
        reporting: {
            clientList: string
            clientTransactions: string
            traderTransactions: string
            tradingAccountList: string
        },
        payments: string
        faq: string
    }
}

export const accountPath = '/account'
export const corpPath = '/account/corporate'
export const dashboardPath = '/dashboard'
export const profilePath = '/profile'
const accountsPath = '/accounts'
const fundsPath = '/funds'
const toolsPath = '/tools'
const verifyPath = '/verify'
const supportPath = '/support'
const personalDetails = '/personal-details'
const systemSettings = '/system-settings'
const simplified = '/s'
const partner = '/ib'
export const ibPath = '/partner'

const Routes: AppRoutes = {
    //these get redirected to welcome
    register: {
        root: `${accountPath}/register`,
        demo: `${accountPath}/register/demo`,
        live: `${accountPath}/register/live`,
        ib: `${accountPath}/register/partner`
    },
    ///
    welcome: {
        demo: `${accountPath}/welcome/demo`,
    },
    account: {
        login: `${accountPath}/login`,
        check2fa: `${accountPath}/login/check`,
        createAccount: {
            demo: `${accountPath}/individual/demo`,
            live: `${accountPath}/individual`
        },
        simplified: {
            accountCreated: `${accountPath}${simplified}/account-created`,
            address: `${accountPath}${simplified}/address`,
            pendingApproval: `${accountPath}${simplified}/pending-approval`,
        },
        ib: {
            q1: `${accountPath}${partner}/q1`,
            q2: `${accountPath}${partner}/q2`,
            legal: `${accountPath}${partner}/legal`,
            accountCreated: `${accountPath}${partner}/account-created`,
            address: `${accountPath}${partner}/address`,
            pendingApproval: `${accountPath}${partner}/pending-approval`,
            verify: {
                id: `${accountPath}${partner}/verify-id`,
                address: `${accountPath}${partner}/verify-address`,
            }
        },
        validateEmail: `${accountPath}/email`,
        checkEmail: `${accountPath}/check-email`,
        congrats: `${accountPath}/congrats`,
        personalInformation: `${accountPath}/personal-information`,
        clientJourney: `${accountPath}/client-journey`,
        accountType: `${accountPath}/account-type`,
        contactAndAddress: `${accountPath}/contact-and-address`,
        selectPlatform: `${accountPath}/select-platform`,
        pammQuestionnaire: `${accountPath}/pamm-questionnaire`,
        financialDetails: `${accountPath}/financial-details`,
        financialDetailsSecondPage: `${accountPath}/financial-details-second`,
        experience: `${accountPath}/experience`,
        appropriatenessTest: `${accountPath}/appropriateness-test`,
        appropriatenessTestAdditional: `${accountPath}/appropriateness-test/additional`,
        chessSponsorship: `${accountPath}/chessSponsorship`,
        usaCitizenship: `${accountPath}/usa-citizenship`,
        foreignExchangeBusiness: `${accountPath}/foreign-exchange-business`,
        pepLegalConsent: `${accountPath}/pep-legal-consent`,
        forexConfirmation: `${accountPath}/forex-confirmation`,
        traderClassification: `${accountPath}/traderClassification`,
        individualFailed: `${accountPath}/individualFailed`,
        individualRefer: `${accountPath}/individualRefer`,
        consent: `${accountPath}/consent`,
        deposit: `${accountPath}/deposit`,
        phoneNumber: `${accountPath}/phone-number`,
        smsVerification: `${accountPath}/sms-verification`,
        resetPasswordStep1: `${accountPath}/resetPasswordStep1`,
        resetPasswordStep2: `${accountPath}/resetPasswordStep2`,
        resetPasswordStep3: `${accountPath}/resetPasswordStep3`,
        resetPasswordStep4: `${accountPath}/resetPasswordStep4`,
        verify: {
            root: `${accountPath}/verify`,
            id: `${accountPath}/verify/id`,
            address: `${accountPath}/verify/address`
        },
        corporate: {
            corporateInformation: `${corpPath}/corporateInformation`,
            contacts: `${corpPath}/contacts`,
            address: `${corpPath}/address`,
            placeOfBusiness: `${corpPath}/placeOfBusiness`,
            regulation: `${corpPath}/regulation`,
            representative: `${corpPath}/representative`,
            directors: `${corpPath}/directors`,
            shareholders: `${corpPath}/shareholders`,
            selectPlatform: `${corpPath}/selectPlatform`,
            financialDetails: `${corpPath}/financialDetails`,
            experience: `${corpPath}/experience`,
            appropriatenessTest: `${corpPath}/appropriateness-test`,
            appropriatenessTestAdditional: `${corpPath}/appropriateness-test/additional`,
            chessSponsorship: `${corpPath}/chessSponsorship`,
            forexConfirmation: `${corpPath}/forex-confirmation`,
            verify: {
                share: `${corpPath}/verify/share`,
                address: `${corpPath}/verify/address`
            },
        }
    },
    dashboard: {
        accounts: {
            root: `${dashboardPath}${accountsPath}`,
            demo: {
                changePassword: `${dashboardPath}${accountsPath}/demo/changePassword`//:accId
            },
            live: {
                changePassword: `${dashboardPath}${accountsPath}/live/changePassword`//:accId
            },
            createDemo: `${dashboardPath}${accountsPath}/demo/create`,
            createLive: `${dashboardPath}${accountsPath}/live/create`,
            mtPassword: `${dashboardPath}${accountsPath}/mt-password`,
            new: `${dashboardPath}${accountsPath}/new`,
        },
        notifications: `${dashboardPath}/notifications`,
        funds: {
            bankAccounts: `${dashboardPath}${fundsPath}/accounts`,
            addBankAccount: `${dashboardPath}${fundsPath}/accounts/add`,
            editBankAccount: `${dashboardPath}${fundsPath}/accounts/edit`,//:accId
            addBankAccountSuccess: `${dashboardPath}${fundsPath}/addBankAccountSuccess`,
            deposit: `${dashboardPath}${fundsPath}/deposit`, //?account=accountNumber
            withdraw: `${dashboardPath}${fundsPath}/withdraw`, //?account=accountNumber
            status: `${dashboardPath}${fundsPath}/status`,
            transfer: `${dashboardPath}${fundsPath}/transfer`, //optional :accId
            history: `${dashboardPath}${fundsPath}/history`, //?account=accountNumber
            cancelWithdraw: `${dashboardPath}${fundsPath}/cancel-withdraw`, //optional :transactionId
            confirmWithdraw: `${dashboardPath}${fundsPath}/confirm-withdraw`,
            phoneValidation: `${dashboardPath}${fundsPath}/phone-validation`,
            emailValidation: `${dashboardPath}${fundsPath}/email-validation`,
            transferFailed: `${dashboardPath}${fundsPath}/transfer-failed`,
            tradeSummary: `${dashboardPath}${fundsPath}/trade-summary`,
        },
        downloads: `${dashboardPath}/downloads`, //optional :platform ?????????
        documents: `${dashboardPath}/documents`,
        partnerships: `${dashboardPath}/partnerships`,
        subscription: `${dashboardPath}/subscription`,
        tools: {
            vps: `${dashboardPath}${toolsPath}/vps`,
            trading: `${dashboardPath}${toolsPath}/trading`,
            myFxBook: `${dashboardPath}${toolsPath}/myfxbook`,
            market_buzz: `${dashboardPath}${toolsPath}/market-buzz`,
            featured_ideas: `${dashboardPath}${toolsPath}/featured-ideas`,
            economic_calendar: `${dashboardPath}${toolsPath}/economic-calendar`,
            analyst_view: `${dashboardPath}${toolsPath}/analyst-view`,
            traders_gym: `${dashboardPath}${toolsPath}/traders-gym`,
            signal_centre: {
                start: `${dashboardPath}${toolsPath}/signal-centre`,
                indicators: `${dashboardPath}${toolsPath}/signal-centre/mt`,
                dashboard: `${dashboardPath}${toolsPath}/signal-centre/dashboard`
            }

        },
        support: {
            faq: `${dashboardPath}${supportPath}/faq`,
            contact: `${dashboardPath}${supportPath}/contact`,
            documents: `${dashboardPath}${supportPath}/documents`,
            clientRequest: `${dashboardPath}${supportPath}/client-request`,
            clientRequestNew: `${dashboardPath}${supportPath}/client-request/new`,
        },
        thinkCopy: `${dashboardPath}/thinkcopy`,
        thinkCapital: `${dashboardPath}/thinkcapital`,
        referAFriend: `${dashboardPath}/refer-a-friend`,
        verify: {
            root: `${dashboardPath}${verifyPath}`,
            id: `${dashboardPath}${verifyPath}/id`,
            address: `${dashboardPath}${verifyPath}/address`,
            companyRegister: `${dashboardPath}${verifyPath}/companyRegister`,
            companyAddress: `${dashboardPath}${verifyPath}/companyAddress`,
            companyShare: `${dashboardPath}${verifyPath}/companyShare`,
            success: `${dashboardPath}${verifyPath}`
        },
        approved: `${dashboardPath}/approved`,
        marketing: `${dashboardPath}/marketing`,
    },
    profile: {
        root: `${profilePath}`,
        personalDetails: `${profilePath}${personalDetails}`,
        uploadDocuments: `${profilePath}/upload-documents`,
        systemSettings: {
            root: `${profilePath}${systemSettings}`,
            loginDetails: `${profilePath}${systemSettings}/login-details`,
            security: `${profilePath}${systemSettings}/security`,
            language: `${profilePath}${systemSettings}/language`,
        },
    },
    ib: {
        dashboard: `${ibPath}/dashboard`,
        reporting: {
            clientList: `${ibPath}/client-list`,
            clientTransactions: `${ibPath}/reporting/client-transactions`,
            traderTransactions: `${ibPath}/reporting/trader-transactions`,
            tradingAccountList: `${ibPath}/reporting/trading-account-list`,
        },
        payments: `${ibPath}/payments`,
        faq: `${ibPath}/faq`,
    }

}

export default Routes
export interface AccountIdMatch {
    accId: string
}

export interface AccountNumberMatch {
    accountNumber: string
}
export interface StatusMatch {
    status: string
}
